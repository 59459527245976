import React from "react";
import { Col, Row, Button, Container, Image, Card } from "react-bootstrap";
import Link from "next/link";
import Layout from "../components/layout";
import ServerProps from "../lib/serverProps";
import offersController from "../lib/offers/offersController";
import pagesController from "../lib/pages/pagesController";
import serializable from "../lib/serializable";
import OfferCard from "../components/offers/OfferCard";
import Subscribe from "../components/Subscribe";
import HomeBlocks from "../components/HomeBlocks";
import LoginModal from "../components//auth/LoginModal";
import Form from "react-bootstrap/Form";
import PostsController from "../lib/posts/postsController";
import Post from "../components/posts/Post";
import PrivateRequestAccessController from "../lib/offers/privateAccessRequestControllers";

const IndexPage = ({ state, data, privateOffersByUser, interested }) => {
  const login = () => {
    LoginModal.register(`/`);
  };

  // admin privet offer request list and request status
  const privateOffersByUserList = {};

  // manipulating privet offer request list and request status
  privateOffersByUser.forEach(
    (offer) => (privateOffersByUserList[offer?.OfferId] = offer?.status)
  );

  // appending "acceptedPrivateRequest" as a key with it's status (applied / accepted / rejected ) as the value
  // for all private requests offer objects
  const privateOffers =
    data?.privateOffers?.map((offer) => {
      if (privateOffersByUserList.hasOwnProperty(offer.id)) {
        offer["acceptedPrivateRequest"] = privateOffersByUserList[offer.id];
        return offer;
      }
      return offer;
    }) || [];

  return (
    <Layout
      title="Venture Capital investing for everyone"
      invertNav
      meta={{
        card: "summary_large_image",
        description:
          "Stride Equity is an equity crowdfunding platform, allowing retail and wholesale investors to invest in Australian startups and scaleups.",
      }}
      state={state}
    >
      {/* bg1 */}
      <div className="bg1 text-center home-content">
        <div className="main-home-wrapper">
          <Container className="container-home">
            <div className="container-logo-wrapper">
              <div className="home-hero-logo">
                <img
                  src="static/logo-light.png"
                  alt="hero-logo"
                  className="home-logo logo-desktop d-none d-lg-block"
                />
                <img
                  src="static/logo-centered.svg"
                  alt="hero-logo"
                  className="home-logo logo-mobile d-block d-lg-none"
                />
              </div>
            </div>
            <div className="container-text-wrapper">
              <div className="home-hero-text">
                <p className="home-hero-title">
                  Combining the resources of venture capital with the power of
                  equity crowdfunding
                </p>
              </div>
              <div className="container-button-wrapper">
                <Button
                  className="invest-button button-hero"
                  variant="light"
                  href="/invest"
                >
                  Invest
                </Button>
                <Button
                  className="learn-more-button button-hero"
                  variant="outline-light"
                  href="/raise"
                >
                  Learn More
                </Button>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Container size="xl" className="home-investment-content">
        <Row className="investment-content-wrapper">
          <Col className="col-lg-7 col-md-12 invest-text-wrapper">
            <div className="invest-title">
              <h1 className="invest-main-title">
                Unleash Your Startup’s Potential
              </h1>
            </div>
            <div className="invest-image-wrapper">
              <img
                src="static/Maria Halasz - Homepage Image.jpg"
                alt="invest-image"
                className="invest-image d-block d-md-none"
              />
            </div>
            <div className="invest-para-content">
              <img
                src="static/Invest-logo.svg"
                alt="invest-logo"
                className="invest-logo"
              />
              <div className="invest-para">
                <p>
                  Inspired by the next generation of Australian entrepreneurs
                  and investors, Stride Equity goes beyond just capital. We take
                  the time to understand your business and capital requirements
                  and connect you to our network of retail and sophisticated
                  investors. We can support your capital raising with our
                  digital investment platform, whether you want a private offer,
                  or wish to raise money from retail and wholesale investors via
                  a crowdsourced funding campaign.
                </p>
                <p>
                  Once you are part of the Stride community you will benefit
                  from our support beyond the capital raising campaign and can
                  take advantage of our corporate advisory services, mentoring
                  and networks. If you are an early-stage company you can
                  leverage our capital raising experience and improve your
                  fundraising success via our SPRINT Investment Ready Program.
                </p>
              </div>
            </div>
          </Col>
          <Col className="col-lg-5 col-md-12">
            <div className="invest-image-wrapper">
              <img
                src="static/Maria Halasz - Homepage Image.jpg"
                alt="invest-image"
                className="invest-image d-none d-md-block"
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container size="xl" className="home-grid-content my-3">
        <div class="my-3 page-horizontal-line"></div>
        <Row className="mt-0 pt-0">
          <div className="responsive-two-grid">
            <div className="grid-content-wrapper col-lg-6 col-md-12 col-sm-12">
              <div className="grid-heading-wrapper">
                <h5 className="grid-small-title mb-0">UNLOCKING POTENTIAL</h5>
                <h1 className="grid-main-title mt-0">
                  Partner with experts for lasting impact
                </h1>
              </div>
              <div className="grid-para-wrapper">
                <p className="grid-paragraph first-paragraph">
                  We know what it takes to succeed. Stride Equity carefully
                  selects companies with the potential to make a significant
                  impact. Our portfolio companies benefit from our extensive
                  experience, knowledge, and connections. With decades of
                  success in venture capital investing and operating emerging
                  businesses through economic cycles, we offer a uniquely
                  qualified perspective to unlock the growth potential of our
                  investee companies.
                </p>
              </div>
              <div className="grid-image-wrapper mt-2">
                <img
                  src="static/Mushroom.png"
                  alt="grid-image"
                  className="grid-image"
                />
              </div>
            </div>

            <div class="vl"></div>

            <div className="grid-content-wrapper col-lg-6 col-md-12 col-sm-12">
              <div class="grid-page-horizontal-line mobile-line"></div>
              <div className="grid-heading-wrapper">
                <h5 className="grid-small-title mb-0">
                  INDUSTRY LEADING EXPERTISE
                </h5>
                <h1 className="grid-main-title mt-0">
                  A community designed to increase value
                </h1>
              </div>
              <div className="grid-para-wrapper">
                <p className="grid-paragraph mb-3">
                  At Stride Equity, we support founders as they navigate the
                  complexities of business building. Our selection process is
                  unique and focused on founders with industry experience and
                  the required persistence to deliver. We love products with
                  unique market positioning that cut through the noise. We
                  carefully select opportunities and work with entrepreneurs who
                  are dedicated business builders. We work hard to increase the
                  success of our portfolio companies.
                </p>
                <div className="pb-3 ">
                  <span className="mr-1">Learn more</span>
                  <Link href={"/about"}>
                    <a className="grid-para-link">about us</a>
                  </Link>
                </div>
              </div>
              <div className="grid-image-wrapper mt-2">
                <img
                  src="static/DRAFT_Stride Equity Brand Guidelines_v1 1.png"
                  alt=""
                  className="grid-image"
                />
              </div>
            </div>
          </div>
        </Row>
      </Container>

      <Container size="xl" className="home-banner-content my-3">
        <div class="my-3 page-horizontal-line"></div>
        <Row className="investment-banner-wrapper">
          <Col className="col-lg-6 banner-text-wrapper">
            <div className="banner-title-wrapper">
              <h1 className="banner-title">Get started today</h1>
            </div>
            <div className="banner-button">
              <Link href="/invest">
                <Button
                  className="invest-button button-hero"
                  variant="primary"
                  href="/invest"
                >
                  Invest
                </Button>
              </Link>
              <Link href="/raise">
                <Button
                  className="raise-button button-hero"
                  variant="light"
                  href="/raise"
                >
                  Raise
                </Button>
              </Link>
            </div>
          </Col>
          <Col className="col-lg-6 banner-image-content">
            <div className="banner-image-wrapper">
              <img
                src="static/Banner-img.png"
                alt="banner-image"
                className="banner-image desktop-view"
              />
              <img
                src="static/DRAFT_Stride Equity Brand Guidelines_v1 6.png"
                alt="banner-image"
                className="banner-image mobile-view"
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container size="xl" className="home-investment-carousel my-3">
        <Row>
          <Col>
            <div className="my-3 page-subtitle-area">
              <a
                href="/invest"
                className="link-secondary float-right d-none d-md-inline-block"
              >
                View All
              </a>
              <h3 className="title-3 text-center">Get Started Today</h3>
            </div>
            {Object.keys(data.offers).length === 0 &&
            privateOffers.length === 0 ? (
              <div className="text-center opp-comming">
                <h2 className="home-title">
                  Investment opportunities are coming soon!
                </h2>

                {Object.keys(state.user).length === 0 && (
                  <>
                    <p className="home-title">
                      Join our growing investor community to get notified about
                      upcoming deals!
                    </p>
                    <Button onClick={login} class="btn btn-primary">
                      JOIN
                    </Button>
                  </>
                )}
              </div>
            ) : (
              <OfferCard.Carousel
                offers={[...data.offers, ...privateOffers]}
                user={state?.user}
                interest={interested}
              />
            )}
          </Col>
        </Row>
      </Container>

      <Container size="xl" className="home-support-content my-3">
        <div class="my-3 page-horizontal-line"></div>
        <Row className="home-support-wrapper">
          <Col className="col-lg-6 support-text-wrapper">
            <div className="support-title">
              <h5 className="support-sub-title">
                SUPPORTING OUR PORTFOLIO COMPANIES
              </h5>
              <h1 className="support-main-title">
                Raising capital and accelerating growth
              </h1>
            </div>
            <div className="support-image-wrapper mobile-view">
              <img
                src="static/DRAFT_Stride Equity Brand Guidelines_v1 3.png"
                alt="invest-image"
                className="support-image d-block d-md-none"
              />
            </div>
            <div className="support-para-content">
              <p className="support-para">
                We invest our experience, expertise and connections to increase
                trust in the investment opportunities we present to retail and
                sophisticated investors. Once we endorse you, our investor
                community has the confidence that drives a successful funding
                event and long term growth. Stride Equity Enabled portfolio
                companies benefit from our mentorship, strategic advice and
                access to our extensive networks, all of which are essential for
                accelerating growth and success.
              </p>
            </div>
            <div className="support-button-content mt-2">
              <Link href="/raise">
                <Button className="btn support-btn" variant="primary">
                  Check Your eligibility
                </Button>
              </Link>
            </div>
          </Col>
          <Col className="col-lg-6">
            <div className="support-image-wrapper">
              <img
                src="static/DRAFT_Stride Equity Brand Guidelines_v1 3.png"
                alt="invest-image"
                className="support-image d-none d-md-block"
              />
            </div>
          </Col>
        </Row>
      </Container>

      <Container size="xl" className="home-news-content">
        <div className="my-3 page-subtitle-area">
          <h3 className="title-3 text-center">Latest News</h3>
        </div>
        <Row>
          <div className="home-news-wrapper">
            <Post.List posts={data.posts} state={state} />
          </div>
        </Row>
      </Container>
    </Layout>
  );
};

export async function getServerSideProps({ query, req, res }) {
  const state = await ServerProps.getState(req);
  const data = {
    offers: await offersController.getFeatured(),
    pages: await pagesController.getFeatured(),
    posts: await PostsController.getPosts({ status: "published" }),
    privateOffers: await offersController.getPrivate(),
  };
  // private offers that user request has accepted by the admin
  let privateOffersByUser = [];
  let interested = [];

  if (state?.user?.id) {
    privateOffersByUser =
      await PrivateRequestAccessController.getApprovedOfferIds(state.user.id);

    interested = await offersController.getInterestedOffers(state?.user.id);
  }
  data.posts = data.posts.slice(0, 4);
  return {
    props: {
      state: await ServerProps.getState(req),
      data: serializable(data),
      privateOffersByUser: serializable(privateOffersByUser),
      interested: serializable(
        interested?.map((interest) => interest?.offerId)
      ),
    },
  };
}

export default IndexPage;
